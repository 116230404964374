import React from "react";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import "./About.scss";
import values from "../../Mock/Values";
import { LazyLoadImage } from "react-lazy-load-image-component";

const About = () => {
  return (
    <>
      <Header />
      <section className="about">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">About Us</p>
        </div>
        <div id="header-img-wrapper" className="d-flex">
          <LazyLoadImage
            effect="blur"
            src="https://redberyl.co/images%20only/Desktop-Header-images.jpg"
            alt="Abt-img"
            className="abt-img header-img"
          />
          <section className="txt">
            <h1 id="font-size-heading-32" className="font-f37">
              About Us
            </h1>
            <br />

            <p className="f-8">
              RedBeryl™ is a premier luxury lifestyle management company
              dedicated to curating exceptional journeys and personalized
              services for our esteemed members.
            </p>

            <p className="f-8">
              Offering unparalleled access to the finest experiences, exclusive
              events, and a dedicated team of lifestyle experts, our passion for
              excellence paves your gateway to an elevated life.
            </p>
          </section>
        </div>

        <hr className="c-red" />
        <h1 id="font-size-heading-32" className="font-f37">
          Brand Story
        </h1>

        <p className="f-8">
          At RedBeryl™, we believe that life should be a collection of
          remarkable moments.
        </p>

        <p className="f-8">
          Our story is rooted in the vision of our founder, Manoj Adlakha, who
          sought to create a platform that transcends the ordinary. Driven by
          his passion to bring an impeccable membership service in India, Manoj
          set out to curate bespoke experiences that leave a lasting impression
          to those with access.
        </p>

        <hr className="c-red" />
        <h1 id="font-size-heading-32" className="font-f37">
          Our Moral Compass
        </h1>
        <p className="f-8">
          An internal guide that helps individuals distinguish between right and wrong, influencing their decisions and actions. It encompasses personal values, ethics, cultural influences, and emotional responses.

        </p>
        <h1 id="font-size-heading-28" className="font-f37">
          I am Committed-
        </h1>
        <div className="f-8">

        <ol>
          <li className="f-8"><strong>Complete Product Knowledge:</strong> It's essential for everyone to have a thorough understanding of our product features and benefits.</li>
          <li className="f-8"><strong>Work-from-Home Flexibility:</strong> While we{"'"}ve been granted flexibility with remote work, it{"'"}s important not to misuse it. Stay focused on your goals. Please ensure you arrive on time for the two days you are required to work in the office each week.</li>
          <li className="f-8"><strong>Service Excellence:</strong> Let{"'"}s continue to provide the highest levels of service to our members at all times.</li>
          <li className="f-8"><strong>Product Focus:</strong> Conversations should center solely on our products. While it's important to be aware of competitors, never speak negatively about them in interactions with prospects or customers.</li>
          <li className="f-8"><strong>Timely Task Completion:</strong> Ensure that all assigned tasks are completed on time to avoid the need for follow-ups.</li>
          <li className="f-8"><strong>Hiring Criteria:</strong> When hiring, leaders should prioritize attitude, passion, and drive over immediate lead generation potential. This principle is especially crucial given the current hiring surge, and it has been our practice from the beginning.</li>
          <li className="f-8"><strong>Data Confidentiality:</strong> Sharing our member database with anyone outside the company is strictly prohibited.</li>
        </ol>
        </div>

        <hr className="c-red" />
        <h1 id="font-size-heading-32" className="font-f37">
          Our Core Values
        </h1>

        <p id="font-size-text-16" className="f-8">
          Our core values serve as the guiding principles behind everything we
          do:
        </p>
        <div className="values-wrapper">
          {values.map((v, i) => (
            <section className="values" key={i}>
              <div className="valueImg">
                <LazyLoadImage
                  effect="blur"
                  src={v.img}
                  alt="value"
                  className="test"
                />
              </div>
              <div className="text">
                <h2 id="font-size-subheading-28">{v.headline}</h2>
                <p id="font-size-text-16">{v.text}</p>
              </div>
            </section>
          ))}
        </div>
        <hr className="c-red" />
        <h1 id="font-size-heading-32" className="font-f37">
          Our Team
        </h1>

        <p className="f-8">
          At RedBeryl™, a passionate and experienced team strives to create
          exceptional experiences. With diverse expertise in luxury hospitality,
          travel, events, and personal concierge services, they are committed to
          making your dreams a reality.
        </p>

        <section className=" grid-container">
          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Manoj.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Manoj Adlakha
              </h1>
              <p className="f-8 font-italic">
                Founder & Chief Executive Officer
              </p>
              <br />
              <p className="f-8">
                Manoj Adlakha is an accomplished business leader with an
                illustrious career spanning over three decades with renowned
                organisations like American Express, Visa, and Yes Bank. A
                Chartered Accountant with a BCom Honors degree from SRCC, Manoj
                honed his leadership skills through an Executive Leadership
                Program at Duke University. As the Senior VP and Head of
                International Marketing at American Express for over 30 years,
                he played a vital role in driving the organisation towards
                success. He spearheaded Amex India as CEO, earning eight
                consecutive years of recognition as India's Most Respected
                Credit Brand. At RedBeryl, he brings his vast knowledge,
                strategic planning and customer-centric expertise to deliver
                exceptional luxury experiences and steer the company's growth as
                the Founder and CEO.
              </p>
            </div>
          </div>
          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Aamith.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Aamith Menon{" "}
              </h1>
              <p className="f-8 font-italic">CTO & National Head Servicing</p>
              <br />
              <p className="f-8">
              With over 27 years of leadership experience across Operations, Digital Transformation, Sales, and Marketing, Aamith’s mission is to disrupt the status quo and redefine industry standards by crafting seamless, forward-thinking customer experiences. He holds an MBA and a degree in Political Science from Delhi University. As the driving force behind our servicing, technology, and digital transformation initiatives, Aamith is committed to innovation and operational excellence. Aamith’s career includes key roles at Lifesight Tech, Getit Infomedia/Askme, Hero Group, and Justdial, where he led transformative projects, redefining customer experiences and business growth. A lifelong learner and innovator, Aamith is passionate about emerging technologies, travel, and food – always seeking new ways to challenge the norm and elevate industry standards.
              </p>
            </div>
          </div>
          {/* <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Anjali.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Anjali Dubey
              </h1>
              <p className="f-8 font-italic">Chief Operating Officer</p>
              <br />
              <p className="f-8">
                With over 20 years of corporate experience in top MNCs like
                American Express, Genpact, and IBM, Anjali is an expert in
                customer experience, organisational development and business
                transformation. She operated a successful consulting business
                specialising in Content Development and Learning & Development
                (L&D). She holds double Master's degrees in Arts and is
                certified in Instructional Design, Content Writing & Marketing,
                trained in Lean and Six Sigma methodology.
              </p>
            </div>
          </div> */}
          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Mohita.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Mohita Mhatre
              </h1>
              <p className="f-8 font-italic">National Head Sales</p>
              <br />
              <p className="f-8">
                Mohita is a postgraduate in marketing and e-commerce with 20
                years of experience in operations, sales and customer service in
                premium space, training colleagues. She has worked with
                companies like Heavens Portfolio, American Express, ICICI Bank
                and Standard Chartered Bank. She is exceptionally talented in
                forging key partnerships, sales and marketing management. She is
                a sportsperson and is passionate about fitness. She runs, swims,
                does Yoga and loves travelling.
              </p>
            </div>
          </div>


          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/manika_jain.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Manika Jain
              </h1>
              <p className="f-8 font-italic">Chief of Staff – Office of Founder and CEO</p>
              <br />
              <p className="f-8">
                Manika is a Management graduate from IMT Ghaziabad who has worked for brands like ABN AMRO, American Express, Google, and Amazon. She considers herself an open-minded and highly adaptable individual with excellent communication skills. With over 17 years of successful experience, she is a meticulous, solution-focused, and result-oriented professional. An out-of-the-box thinker, Manika has a flair for identifying and adopting emerging trends while addressing industry requirements to achieve organizational objectives and profitability norms. She has a customer-centric approach that emphasizes healthy and strong relationships.
              </p>
            </div>
          </div>

          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Aanshiki.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
              Aanshiki Mittal{" "}
              </h1>
              <p className="f-8 font-italic">
              Chief Brand Officer
              </p>
              <br />
              <p className="f-8">
              Aanshiki, a vibrant professional with over 12 years of experience, has cartwheeled through some of the biggest names across disparate industries, like HDFC Bank, Paisa Bazaar, Times of India, and TikTok; building a rather versatile & unique set of capabilities. Additionally, she even has her own creative label - Found by Aanshiki, based on exploration of experimental art forms!  As the Chief Brand Officer, her passion for disruptive ideas and the power of storytelling creates waves, delivering unmatched vision for the brand. Outside the professional sphere, Aanshiki's heart belongs to her three dogs. This unique blend of professional versatility and personal warmth defines her creative leadership.
              </p>
            </div>
          </div>
          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Shruti.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
              Shruti Joshi{" "}
              </h1>
              <p className="f-8 font-italic">
              AVP - Acquisition (South)
              </p>
              <br />
              <p className="f-8">
              A postgraduate in Marketing Management from Pune University, Shruti began her career with Pantaloons India and later enhanced her expertise at Hidesign, Multimedia Communications, and American Express, where she proudly served as one of the first Centurion Ambassadors of the country. With over 12 years of experience in sales and business development, she bring a proven track record of driving growth, building strong client relationships, and achieving exceptional results across diverse industries. Outside of work, Shruti is passionate about reading, traveling, and exploring new cultures, which constantly inspire her personal and professional growth.
              </p>
            </div>
          </div>
          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Smita.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
              Smita Singh{" "}
              </h1>
              <p className="f-8 font-italic">
              Head of Servicing North & East and National Training Head
              </p>
              <br />
              <p className="f-8">
              Smita Singh is on a mission to elevate industry standards and create exceptional service experiences. With over 17 years of diverse experience, her career journey includes key roles at HCL and American Express, where she developed expertise in customer service training, credit retention, and sales. Smita excels in driving revenue generation, talent acquisition, and ensuring both customer and employee satisfaction. Her passion lies in building high-performing teams and cultivating lasting customer loyalty. Outside of her professional life, Smita enjoys reading, traveling, and engaging in meaningful conversations. She values quality time with her friends and family, always seeking to broaden her horizons.
              </p>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default About;
