import axios from "axios";
export const baseURL = "https://api.redberyl.co/v1";
 
const instance = axios.create({
  baseURL: baseURL,
  
});

export const NewContactUs = (data) => {
  return instance.post("/contact-us", data);
};
export const AddMembership = (data) => {
  return instance.post("/add-membership", data);
};
export const PayNow = (data) => {
  return instance.post("/init/txn", data);
}