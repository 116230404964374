import React from "react";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

function Gtags({ title, meta }) {
  const tagManagerArgs = {
    gtmId: "G-KYNL3H1GJG",
  };

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="UTF-8" />
      <meta
        name="description"
        content={meta.description ? meta.description : "Not Found"}
      />
      <meta
        name="keywords"
        content={
          meta.keywords
            ? meta.keywords.length && meta.keywords.join(",")
            : "Not Found"
        }
      />
      {/* <meta name="keywords" content={meta.keywords.join(",")} /> */}
    </Helmet>
  );
}

export default Gtags;
